<template>
  <router-link class="inline-block" to="/">
    <img
      alt="Booodata logo"
      src="@/assets/logo-header.svg"
      width="118"
      height="46"
    />
  </router-link>
</template>

<script>
export default {
  name: "BaseLogo",
};
</script>
