<template>
  <button
    class="w-full border rounded font-medium flex items-center justify-center"
    :class="[
      big ? 'px-4 py-2 min-w-56' : 'px-2 py-1',
      colorScheme === 'dark'
        ? 'text-black-600 hover:bg-black-900 hover:text-white-900 border-black-900'
        : 'border-orange-900 hover:bg-orange-900 hover:text-white-900',
      active
        ? 'bg-orange-900 text-white-900'
        : colorScheme === 'dark'
        ? ''
        : 'text-orange-900',
    ]"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    big: {
      type: Boolean,
      required: false,
    },
    colorScheme: {
      type: String,
      default: () => "dark",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
