<template>
  <div class="flex flex-grow justify-center items-center flex-col pl-500px">
    <div class="max-w-400 w-full mx-auto">
      <h2 class="text-4xl font-medium text-black-900 mb-3">Forgot password</h2>
      <p class="text-black-900 text-base mb-8 pr-20">
        Enter the email address you used when you joined and we’ll send you
        instructions to reset your password.
      </p>
      <validation-observer
        tag="form"
        ref="form"
        @submit.prevent="handleSubmit"
        class="space-y-6 mb-11"
      >
        <utils-input
          :validationRules="'required|email'"
          :inputName="'Email'"
          :inputType="'text'"
          :isSubmitted="isSubmitted"
          v-model="data.email"
        />

        <base-button colorScheme="orange" class="mt-8 py-3 w-full">
          <span class="font-normal text-sm"> Forgot password</span>
        </base-button>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";

import BaseButton from "@/components/utils/Button.vue";
import UtilsInput from "@/components/utils/UtilsInput.vue";
import { AuthService } from "@/common/api.service";

export default {
  name: "AuthForgotPasswordForm",
  components: {
    "utils-input": UtilsInput,
    "base-button": BaseButton,
    ValidationObserver,
  },
  data() {
    return {
      isSubmitted: false,
      data: {
        email: "",
      },
    };
  },
  methods: {
    handleSubmit() {
      this.isSubmitted = true;

      this.$refs.form.validate().then(async (valid) => {
        if (!valid) {
          return;
        }

        try {
          await AuthService.forgotPassword(this.data);

          this.$swal({
            icon: "success",
            title: "Forgot password requested",
            text: "We have e-mailed your password reset link!",
          });
          this.data.email = "";
        } catch (error) {
          const data = error.response.data;
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: data.error,
          });
        }
      });
    },
  },
};
</script>
