var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"w-full border rounded font-medium flex items-center justify-center",class:[
    _vm.big ? 'px-4 py-2 min-w-56' : 'px-2 py-1',
    _vm.colorScheme === 'dark'
      ? 'text-black-600 hover:bg-black-900 hover:text-white-900 border-black-900'
      : 'border-orange-900 hover:bg-orange-900 hover:text-white-900',
    _vm.active
      ? 'bg-orange-900 text-white-900'
      : _vm.colorScheme === 'dark'
      ? ''
      : 'text-orange-900' ]},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }